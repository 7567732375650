.service-card {
  background: var(--card-color);
  border-radius: 12px;
  box-shadow: var(--card-shadow);
  height: 300px;
  max-width: 500px;
  padding: 10px;
  position: relative;

  &.clickable:hover {
    cursor: pointer;
  }

  &.removing {
    // filter: blur(3px);
  }
}

.service-card-loader-overlay {
  filter: blur(3px);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.service-card-loader {
  align-items: center;
  display: flex;
  filter: none;
  filter: blur(0);
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.service-card-image {
  height: 150px;
  width: 150px;
}

.service-card-close-button {
  appearance: none;
  background-color: grey;
  border: unset;
  border-radius: 50%;
  box-shadow: unset;
  color: white;
  cursor: pointer;
  font-size: 20px;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  height: 25px;
  line-height: 50%;
  margin: 0;
  overflow: visible;
  padding: 0;
  position: absolute;
  right: 10px;
  text-align: center;
  top: 10px;
  width: 25px;

  &:hover {
    background-color: red;
  }
}
