html {
  height: 100%;
}

.link {
  color: #fff;

  :visited,
  a:hover,
  a:active {
    color: inherit;
  }
}

body {
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

:root {
  --card-color: #1f1f1f;
  --card-shadow: 5px 5px 10px rgba(0, 0, 0, 0.199);
  --background-color: #37404d;
  --primary-color: #f9b234;
  --primary-color-shade: #df9b25;
  --text-color: #fff;
}
