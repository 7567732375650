.language-picker-image {
  border-radius: 50%;
  height: 50px;
  object-fit: cover;
  width: 50px;
}

.language-picker-list-image {
  // display: none;
}

.language-picker-container {
  background-color: var(--background-color);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;

  &.is-open > .language-picker-list-image {
    margin-top: 10px;
  }

  &.is-open {
    height: 100%;
  }
}
