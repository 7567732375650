
.action-button {
  background: var(--primary-color);
  border: unset;
  border-radius: 4px;
  box-shadow: none;
  color: var(--text-color);
  cursor: pointer;
  min-height: 40px;
  min-width: 120px;
  transition: all 0.1s;
  width: 80px;
}

.action-button:hover {
  box-shadow: 2px 2px 7px var(--primary-color-shad);
}
