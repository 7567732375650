.input {
  background: none;
  border: unset;
  border-bottom: solid rgb(143, 143, 143) 1px;
  color: rgba(255, 255, 255, 0.555);
  height: 35px;
  margin-bottom: 30px;
  width: 100%;
}

.input-label {
  width: 100%;
}
