h2 {
  color: white;
  font-size: 35px;
  font-weight: 800;
}

.flex-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100vw;
}

.form-container {
  align-items: center;
  background: var(--card-color);
  border-radius: 12px;
  box-shadow: var(--card-shadow);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px;
  padding-top: 20px;
  position: relative;
  width: 400px;

  @media only screen and (max-width: 500px) {
    border-radius: unset;
    height: 100%;
    padding-bottom: unset;
    padding-top: unset;
  }
}

.form-container-language-picker {
  position: absolute;
  right: 10px;
  top: 10px;
}

.subtitle {
  color: rgba(177, 177, 177, 0.3);
  font-size: 11px;
}

.login-logo {
  height: 200px;
}

.login-view-input {
  margin-top: 20px;
  width: 100%;
}

.login-view-submit-button {
  margin-top: 35px;
}
