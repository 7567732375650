@import "../../styles/hex-to-filter-mixin";

.services-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.services-grid{
  overflow-x: auto;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
  text-align: center;
}

.service-card-image-add {
  height: 150px;
  width: 150px;
  @include hex-to-filter(#fff);
}

.services-modal-title-container{
  display: flex;
  justify-content: center;

  color: white;
  font-size: 35px;
  font-weight: 800;
}

.modal {
  height: 80vh;
  margin: 100px;
  background-color: var(--background-color);
  border-radius: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 50;
  background-color: rgba(0, 0, 0, 0.6);
}
.user-api-key {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.user-api-key span {
  max-width: 150px;
  overflow-x: hidden;
  margin: 0 10px;
}