.top-bar {
  display: flex;
  flex: 1;
  max-height: 60px;
  min-height: 60px;
  background-color: var(--card-color);
}

.top-bar-logout-button{
  margin: 15px;
}

.top-bar-email{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}